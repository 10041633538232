export const util = {
    formatLsp (value) {
        if (Array.isArray(value)) {
            const obj = {};
            value.map((e) => obj[e] = true);
            return obj;
        } else {
            return [];
        }
    },
    formatImgUrl (arr = [], key = "", name = "图片") {
        if (key) {
            return arr.map(i => {
                return {
                    ...i,
                    name: name,
                    url: i[key],
                    uid: parseInt(Math.random() * 10000000000000)
                }
            })
        } else {
            return arr.map(i => {
                return {
                    name: name,
                    url: i,
                    uid: parseInt(Math.random() * 10000000000000)
                }
            })
        }
    },
    assignObj (obj1, obj2) {
        for (let key in obj1) {
            obj1[key] = (key in obj2) ? obj2[key] : obj1[key]
        }
        return obj1
    },
    geneImg (i) {
        let id1 = i.lastIndexOf('.');
        const name = i.slice(id1);
        return {
            source_img: i,
            big_img: `${i}!t800x800.${name}`,
            thumb_img: `${i}!t300x300.${name}`
        }
    },
    formatTime (dateTime = 0, fmt = 'yyyy-mm-dd') {
        // console.log("dateTime===",dateTime);
        if (dateTime === null || dateTime == 0) return '--';
        // 如果为null,则格式化当前时间
        if (!dateTime) dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length == 10) dateTime *= 1000;
        let date = new Date(dateTime);
        let ret;
        let opt = {
            "y+": date.getFullYear().toString(), // 年
            "m+": (date.getMonth() + 1).toString(), // 月
            "d+": date.getDate().toString(), // 日
            "h+": date.getHours().toString(), // 时
            "M+": date.getMinutes().toString(), // 分
            "s+": date.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    },
    // 将驼峰转为下划线
    camelToUnderline (obj) {
        let newObj = {}
        Object.entries(obj).forEach(([key, value]) => {
            newObj[key.replace(/([A-Z])/g, "_$1").toLowerCase().replace(/_i_d/g, '_id')] = value
        })
        return newObj
    },
    // 处理select框 lable值为空
    toSelectLableStr (str_value) {
        let str_data_value = (str_value ? encodeURI(str_value) : '--').replace(/&nbsp;/g, '%20');
        return decodeURI(str_data_value);
    },

}