let baseUrl, diagImg, h5Url
switch (process.env.NODE_ENV) {
    case 'prod':
        baseUrl = 'https://ykd.ykd360.cn'
        break
    case 'uat':
        baseUrl = 'https://ykd360-api.km-union.com'
        break
    default:
        baseUrl = 'https://ykd360-api.km-union.com'
}
export const baseconfig = {
    v: '1.0.1',
    baseUrl: baseUrl,
    net_error_message: '服务异常',
}