// BaseModule.js
import axios from 'axios'
import qs from 'query-string'
import router from '@/router'
import store from '@/store'
import { baseconfig } from "@/config.js";
import { Message } from 'element-ui'
let ShowMsg = null
class BaseModule {
  constructor() {
    this.$http = axios.create({
      baseURL: baseconfig.baseUrl
    })
    this.baseURL = baseconfig.baseUrl;
    this.access_token = () => store.getters.token;
    this.qs = qs;
    this.geneMethod = (url = '') => {
      return {
        get: param => this.get(`${url}/listv`, param),
        add: param => this.post(`${url}/save`, param),
        update: param => this.post(`${url}/update`, param),
        remove: param => this.post(`${url}/remove`, param)
      }
    }
    // this.user_id = store.getters.adminID
    this.$http.interceptors.request.use(function (config) {
      // 设置请求头
      config.headers.common['v'] = baseconfig.v
      config.headers.common['platform'] = baseconfig.platform
      // config.headers.common['Authorization'] = store.getters.token && ('Bearer ' + store.getters.token)
      if(config.url != 'login'){
        config.headers.common['Authorization'] = store.getters.token && ('Bearer ' + store.getters.token)
      }
      console.log("%c%s", "color: white; background: green; font-size: 14px;", '请求参数:', config.url, config.data || config.params)
      return config;
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    });
    this.$http.interceptors.response.use(function (response) {
      // console.log(response.config)
      console.log("%c%s", "color: red; background: yellow; font-size: 14px;", '请求结果:', response.config.url, response.data)
      // 对响应数据做点什么
      if (response.config.notShowMsg) return response.data;
      switch (Number(response.data.code)) {
        case 200:
          break;
        default:
          if (response.config.url != "common/log/list") {
            Message.error(response.data.message || baseconfig.net_error_message);
          }
          break;
      }
      return response.data;
    }, function (error) {
      try {
        switch (error.response.status) {
          case 401:
            console.log(error.response, 123456)
            if (ShowMsg) return;
            ShowMsg = true;
            Message.error('鉴权失败，请重新登录！');
            store.dispatch('admin/removeToken')
            store.commit('admin/SET_CODE', '')
            if (router.currentRoute.fullPath.indexOf('login') == -1) {
              router.replace({
                path: '/login',
                query: {
                  backurl: router.currentRoute.fullPath
                }
              })
              setTimeout(() => {
                ShowMsg = null
              }, 1000)
            }
            break;
          default:
            Message.error(error.response.data.message || baseconfig.net_error_message);
            break;
        }
      } catch (err) {
        console.log(err, '监听到错误')
        Message.error(baseconfig.net_error_message);
      }

    });
    this.dataMethodDefaults = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: [function (data) {
        return qs.stringify(data)
      }]
    }
  }

  get (url, params = undefined, config = {}) {
    return this.$http.get(url, { params }, config)
  }
  post (url, data = undefined, config = {}) {
    return this.$http.post(url, data, { ...this.dataMethodDefaults, ...config })
  }
  upload (url, data = undefined, config = {}) {
    let formData = new FormData();
    if (data) {
      if (Array.isArray(data.files)) {
        data.files.forEach(i => {
          formData.append('files', i)
        });
        delete data.files;
        for (let key in data) {
          formData.append(key, data[key])
        }
      } else {
        for (let key in data) {
          formData.append(key, data[key])
        }
      }
    }
    return this.$http.post(url, formData, { ...config })
  }
  put (url, data = undefined, config = {}) {
    return this.$http.put(url, data, { ...this.dataMethodDefaults, ...config })
  }
  delete (url, config = {}) {
    return this.$http.delete(url, config)
  }
}

export default BaseModule