const getters = {
    sidebar: state => state.app.sidebar,
	isLockScreen: state => state.app.isLockScreen,
	isEditPwd: state => state.app.isEditPwd,
    token: state => state.admin.accessToken,
    adminID: state => state.admin.adminID,
    roleSign: state => state.admin.roleSign,
    userName: state => state.admin.userName,
    treeList: state => state.app.treeList,
    roleName: state => state.admin.roleName,
}
export default getters