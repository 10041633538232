const state = {
    accessToken: '',
    userName:'',
    branchCode:'',
    roleSign:'',
    roleName:'',
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.accessToken = token
    },
    REMOVE_TOKEN: (state) => {
        state.accessToken = ''
    },
    SET_USERNAME: (state, userName) => {
        state.userName = userName
    },
    REMOVE_USERNAME: (state) => {
        state.userName = ''
    },
    SET_CODE: (state, code) => {
        state.branchCode = code
    },
    SET_ROLESIGN: (state, code) => {
        state.roleSign = code
    },
    REMOVE_ROLESIGN: (state) => {
        state.roleSign = ''
    },
    SET_ROLENAME: (state, code) => {
        state.roleName = code
    },
    REMOVE_ROLENAME: (state) => {
        state.roleName = ''
    },
}
const actions = {
    setToken({ commit }, token) {
        return new Promise(resolve => {
            commit('SET_TOKEN', token)
            resolve()
        })
    },
    removeToken({ commit }) {
        return new Promise(resolve => {
            commit('REMOVE_TOKEN')
            resolve()
        })
    },

    setRoleSign({ commit }, token) {
        return new Promise(resolve => {
            commit('SET_ROLESIGN', token)
            resolve()
        })
    },
    removeRoleSign({ commit }) {
        return new Promise(resolve => {
            commit('REMOVE_ROLESIGN')
            resolve()
        })
    },
    setUserName({ commit }, token) {
        return new Promise(resolve => {
            commit('SET_USERNAME', token)
            resolve()
        })
    },
    removeUserName({ commit }) {
        return new Promise(resolve => {
            commit('REMOVE_USERNAME')
            resolve()
        })
    },
    setRoleName({ commit }, token) {
        return new Promise(resolve => {
            commit('SET_ROLENAME', token)
            resolve()
        })
    },
    removeRoleName({ commit }) {
        return new Promise(resolve => {
            commit('REMOVE_ROLENAME')
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}