import BaseModule from './utils/BaseModule'
class BaseManager extends BaseModule {
    constructor() {
        super()
    }

    //用户列表
    homeList(param) {
        return this.get('web/integralOrderInfo/home', param)
    }

    //查看报告
    getReportDetail(param) {
        return this.get('/web/seekBeautyLogNewInfo/getReportDetail', param)
    }

    //检测报告列表
    getCheckReportList(param) {
        return this.get('pc/expert/getCheckReportList', param)
    }
    //报告对比
    reportContrast(param) {
        return this.get('pc/expert/contrast', param)
    }
}

export default new BaseManager()