// import Cookies from 'js-cookie'

const state = {
    sidebar:false,
	isLockScreen: false,
	isEditPwd: false,
	treeList:[],
	daPingList:[]
}

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar = !state.sidebar
    },
	TOGGLE_ISLOCKSCREEN: state => {
	    state.isLockScreen = !state.isLockScreen
	},
	TOGGLE_ISEDITPWD: state => {
	    state.isEditPwd = !state.isEditPwd
	},
	appAddTree:(state,data)=>{
		state.treeList = data;
	},
	appRemoveTree:state=>{
		state.treeList = '';
	},
	appAddDaPingTree:(state,data)=>{
		state.daPingList = data;
	},
	appRemoveDaPingTree:state=>{
		state.daPingList = '';
	}
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
	toggleIsLockScreen({ commit }) {
	    commit('TOGGLE_ISLOCKSCREEN')
	},
	toggleIsEditPwd({ commit }) {
	    commit('TOGGLE_ISEDITPWD')
	}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}