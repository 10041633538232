import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
// 公用模块
const login = () => import( /* webpackChunkName: "admin" */ '@/components/page/system/login/Login.vue')
const home = () => import( /* webpackChunkName: "admin" */ '@/components/common/layout/Home.vue')
const main = () => import( /* webpackChunkName: "admin" */ '@/components/page/system/main/Main.vue')
const notfound = () => import( /* webpackChunkName: "admin" */ '@/components/page/system/NotFound.vue')

//系统管理模块
const SysUserList = () => import( /* webpackChunkName: "sys" */ '@/components/page/system/adminmanage/SysUserList.vue')
const SysRoleList = () => import( /* webpackChunkName: "sys" */ '@/components/page/system/adminmanage/SysRoleList.vue')
const SysMenuList = () => import( /* webpackChunkName: "sys" */ '@/components/page/system/adminmanage/SysMenuList.vue')
const AgentUserList = () => import( /* webpackChunkName: "sys" */ '@/components/page/agent/AgentUserList.vue')
const ExpertList = () => import( /* webpackChunkName: "sys" */ '@/components/page/system/expert/ExpertList.vue')

/*系统设置 */
const LogoutSettingList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/LogoutSettingList.vue')
const ExplainSettingList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/ExplainSettingList.vue')
const MessageList = () => import( /* webpackChunkName: "set" */ '@/components/page/systemSet/MessageList.vue')
const RateSettingList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/RateSettingList.vue')
const RegionList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/RegionList.vue')
const InvitationTaskList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/InvitationTaskList.vue')
const VersionList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/VersionList.vue')
const AdvertList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/AdvertList.vue')
const ConsultationTypeList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/ConsultationTypeList.vue')
const TerminalList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/TerminalList.vue')
const QuickPhrasesList = () => import( /* webpackChunkName: "sys_set" */ '@/components/page/systemSet/QuickPhrasesList.vue')


//商品管理
const GoodsClassList = () => import( /* webpackChunkName: "goods" */ '@/components/page/goods/GoodsClassList.vue')
const GoodsList = () => import( /* webpackChunkName: "goods" */ '@/components/page/goods/GoodsList.vue')
const GoodsOrderList = () => import( /* webpackChunkName: "goods" */ '@/components/page/goods/GoodsOrderList.vue')
const OrderList = () => import( /* webpackChunkName: "goods" */ '@/components/page/goods/order/OrderList.vue')

//圈子管理
const CircleClassList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/class/CircleClassList.vue')
const TopicList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/topic/TopicList.vue')
const CircleList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/list/CircleList.vue')
const ComplaintTypeList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/complaint/ComplaintTypeList.vue')
const NewsComplaintList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/complaint/NewsComplaintList.vue')
const DynamicList = () => import( /* webpackChunkName: "circle" */ '@/components/page/circle/dynamic/DynamicList.vue')


/*系统监控 */
const MonitorList = () => import( /* webpackChunkName: "monitor" */ '@/components/page/monitor/MonitorList.vue')

//用户管理
const UserList = () => import( /* webpackChunkName: "user" */ '@/components/page/user/UserList.vue')
const OccupationList = () => import( /* webpackChunkName: "user" */ '@/components/page/user/OccupationList.vue')
const BalanceChangeList = () => import( /* webpackChunkName: "user" */ '@/components/page/user/BalanceChangeList.vue')
const WithdrawalList = () => import( /* webpackChunkName: "user" */ '@/components/page/user/WithdrawalList.vue')

//门店管理
const StoreList = () => import( /* webpackChunkName: "store" */ '@/components/page/store/StoreList.vue')

//求美管理
const SeekBeautyLogList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/SeekBeautyLogList.vue')
const CompleteList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/CompleteList.vue')
const SymptomList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/SymptomList.vue')
const CommonSchemeList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/CommonSchemeList.vue')
const ConsultationRecordList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/ConsultationRecordList.vue')
const ReferralRecordList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/ReferralRecordList.vue')
const TaskManagement = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/TaskManagement.vue')
const EntryRecordList = () => import( /* webpackChunkName: "seekBeauty-second" */ '@/components/page/seekBeauty/entryRecord/EntryRecordList.vue')

/** 体验卷管理 */
const CouponList = () => import( /* webpackChunkName: "coupon" */ '@/components/page/coupon/CouponList.vue')
const CouponUseList = () => import( /* webpackChunkName: "coupon" */ '@/components/page/coupon/CouponUseList.vue')

// 问卷
const QuestionList = () => import( /* webpackChunkName: "questionnaire" */ '@/components/page/questionnaire/question/QuestionList.vue')
const TemplateList = () => import( /* webpackChunkName: "questionnaire" */ '@/components/page/questionnaire/template/TemplateList.vue')

// 治疗方案
const AnalysisResultList = () => import( /* webpackChunkName: "treatment" */ '@/components/page/treatment/result/AnalysisResultList.vue')
const TreatmentProjectList = () => import( /* webpackChunkName: "treatment" */ '@/components/page/treatment/project/TreatmentProjectList.vue')
const CompositionList = () => import( /* webpackChunkName: "treatment" */ '@/components/page/treatment/composition/CompositionList.vue')

const DataIndex = () => import( /* webpackChunkName: "daping" */ '@/components/page/daping/DataIndex.vue')



var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: home,
            redirect: '/home',
            meta: { title: '欢迎' },
            children: [
                // 系统管理模块
                { path: '/home', component: main, meta: { title: '首页' } },
                { path: '/sys/user', component: SysUserList, meta: { title: '管理员信息' } },
                { path: '/sys/role', component: SysRoleList, meta: { title: '角色信息' } },
                { path: '/sys/menu', component: SysMenuList, meta: { title: '系统菜单' } },
                { path: '/sys/agent', component: AgentUserList, meta: { title: '代理商管理' } },
                { path: '/sys/expert', component: ExpertList, meta: { title: '专家管理' } },
                // { path: '/sys/agentWithdrawal', component: AgentWithdrawalList, meta: { title: '提现管理' } },

                //系统设置
                { path: '/set/logoutSettingList', component: LogoutSettingList, meta: { title: '注销设置' } },
                { path: '/set/explainSettingList', component: ExplainSettingList, meta: { title: '说明设置管理' } },
                { path: '/set/notify', component: MessageList, meta: { title: '消息管理' } },
                { path: '/set/rateSettingList', component: RateSettingList, meta: { title: '费率管理' } },
                { path: '/set/regionList', component: RegionList, meta: { title: '地区管理' } },
                { path: '/set/invitationTask', component: InvitationTaskList, meta: { title: '邀请任务管理' } },
                { path: '/set/versionList', component: VersionList, meta: { title: '版本管理' } },
                { path: '/set/advertList', component: AdvertList, meta: { title: '广告管理' } },
                { path: '/set/consultationTypeList', component: ConsultationTypeList, meta: { title: '问诊类型' } },
                { path: '/set/terminal', component: TerminalList, meta: { title: '设备管理' } },
                { path: '/set/quickPhrases', component: QuickPhrasesList, meta: { title: '快捷语管理' } },

                


                //商品管理
                { path: '/goods/goodsClassList', component: GoodsClassList, meta: { title: '商品分类管理' } },
                { path: '/goods/goodsList', component: GoodsList, meta: { title: '商品管理' } },
                { path: '/goods/goodsOrderList', component: GoodsOrderList, meta: { title: '商品订单管理' } },
                { path: '/goods/orderList', component: OrderList, meta: { title: '商品订单管理(新)' } },

                //圈子管理
                { path: '/circle/circleClassList', component: CircleClassList, meta: { title: '圈子分类管理' } },
                { path: '/circle/topicList', component: TopicList, meta: { title: '话题管理' } },
                { path: '/circle/circleList', component: CircleList, meta: { title: '圈子管理' } },
                { path: '/circle/complaintTypeList', component: ComplaintTypeList, meta: { title: '动态投诉类型管理' } },
                { path: '/circle/newsComplaintList', component: NewsComplaintList, meta: { title: '动态投诉管理' } },
                { path: '/circle/dynamicList', component: DynamicList, meta: { title: '动态管理' } },

                //系统监控
                { path: '/common/log', component: MonitorList, meta: { title: '系统日志' } },

                //用户管理
                { path: '/user/userList', component: UserList, meta: { title: '用户管理' } },
                { path: '/user/withdrawal', component: WithdrawalList, meta: { title: '提现记录' } },
                { path: '/user/balanceChange', component: BalanceChangeList, meta: { title: '余额变动记录' } },
                { path: '/user/occupation', component: OccupationList, meta: { title: '职业信息' } },

                //门店管理
                { path: '/store/storeList', component: StoreList, meta: { title: '门店管理' } },

                //求美管理
                { path: '/seekBeauty/seekBeautyLog', component: SeekBeautyLogList, meta: { title: '求美日志管理' } },
                { path: '/seekBeauty/completeList', component: CompleteList, meta: { title: '完善信息管理' } },
                { path: '/seekBeauty/symptom', component: SymptomList, meta: { title: '症状信息管理' } },
                { path: '/seekBeauty/commonSchemeList', component: CommonSchemeList, meta: { title: '常用方案管理' } },
                { path: '/seekBeauty/consultationRecordList', component: ConsultationRecordList, meta: { title: '咨询记录管理' } },
                { path: '/seekBeauty/referralRecordList', component: ReferralRecordList, meta: { title: '转诊记录管理' } },
                { path: '/seekBeauty/taskManagement', component: TaskManagement, meta: { title: '待回复任务管理' } },
                { path: '/seekBeauty/entryRecord', component: EntryRecordList, meta: { title: '入店记录管理' } },
                

                //体验券管理
                { path: '/coupon/couponList', component: CouponList, meta: { title: '体验券管理' } },
                { path: '/coupon/couponUseList', component: CouponUseList, meta: { title: '使用记录' } },

                // 问卷管理
                { path: '/questionnaire/questionList', component: QuestionList, meta: { title: '问题管理' } },
                { path: '/questionnaire/templateList', component: TemplateList, meta: { title: '问卷模版管理' } },

                 // 治疗方案
                 { path: '/treatment/result', component: AnalysisResultList, meta: { title: '分析结果管理' } },
                 { path: '/treatment/project', component: TreatmentProjectList, meta: { title: '治疗方案管理' } },
                 { path: '/treatment/component', component: CompositionList, meta: { title: '产品成分管理' } },
                
                 

                {
                    path: '/404',
                    component: notfound,
                    meta: { title: '页面不存在' }
                }
            ]
        },
        {
            path: '/login',
            component: login,
            meta: { title: '登录' }
        },
        {
            path: '/dataIndex',
            component: DataIndex,
            meta: { title: '医可达大数据可视化' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
})

export default router

router.beforeEach((to, from, next) => {

    if (to.meta.title) {
        document.title = to.meta.title
    }

    NProgress.start()
    const token = store.getters.token
    if (!token && to.path != '/login') {
        next('/login');
    } else {
        next();
    }
    // if (to.path == '/dataIndex') {   //2023-03-13 注释 大屏也需要登录才可以查看
    //     next();
    // } else {
    //     if (!token && to.path != '/login') {
    //         next('/login');
    //     } else {
    //         next();
    //     }
    // }
})

router.afterEach(transition => {

    NProgress.done()

})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}