<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import homeManager from '@/request/homeManager.js'
import $ from "jquery";
let timeInterval
let titleInterval
export default {
  created () {
    if (this.$store.getters.roleName == '超级用户角色' || this.$store.getters.roleName == '管理员' || this.$store.getters.roleName == '大管家' || this.$store.getters.roleName == '客服') {
      this.$bus.$on("refreshHomeNumData", eventRes => {
        if (eventRes.isRefreshLogin) this.getHomeNumberData();
      })
      this.getHomeNumberData();
      timeInterval = setInterval(() => {
        this.getHomeNumberData();
      }, 20000)
    }
  },
  methods: {
    async getHomeNumberData () {
      if (!this.$store.getters.token) return;
      try {
        let { code, data } = await homeManager.homeList()
        if (code === 200) {
          this.getHandleTitleStyle(data.waitReplyNumber || 0)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 处理游览器标签跑马灯
    getHandleTitleStyle (wait_reply_number) {
      if (wait_reply_number > 0) {
        var title = '您有求美日志待回复任务；';
        var temptitle = title;
        var length = title.length;
        titleInterval = setInterval(() => {
          temptitle = temptitle.concat(temptitle.charAt(0));
          length = temptitle.length;
          temptitle = temptitle.substring(1, length);
          $("title").html(temptitle);
          if (this.$route.path == '/login') this.getHandleTitleStyle(0);
        }, 800);
      } else {
        if (titleInterval) clearInterval(titleInterval);
        $("title").html(this.$route.meta.title);
        this.$bus.$emit('refreshHomeNumData', {
          isRefreshHomeMain: this.$route.path == '/home',
        });
      }
    },
  },
  beforeDestroy () {
    if (timeInterval) clearInterval(timeInterval);
    if (titleInterval) clearInterval(titleInterval);
  },
}
</script>
<style>
@import './assets/css/common.css';
@import './assets/css/main.css';
@import './assets/css/theme.css';
@import './assets/css/flex.css';
@import './assets/css/daping.css';

/* html,body,#app{
 height: 100%;
} */
</style>
