import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import store from './store'
// import * as echarts from 'echarts';
import { util } from "@/tools/util.js";
Vue.use(VueRouter)
import './element-variables.scss'
Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
});
Vue.prototype.$util = util;
import bus from './components/common/bus.js'
Vue.prototype.$bus = bus 
// Vue.prototype.$echarts = echarts
//高德地图
// import VueAMap from 'vue-amap';
// // 初始化vue-amap
// VueAMap.initAMapApiLoader({
//     key: '49be3086902cbc468b8ef2db5ea86e59',
//     // 插件集合 （插件按需引入）
//     plugin: [
//         "AMap.Autocomplete", //输入提示插件
//         "AMap.PlaceSearch", //POI搜索插件
//         "AMap.Scale", //右下角缩略图插件 比例尺
//         "AMap.OverView", //地图鹰眼插件
//         "AMap.ToolBar", //地图工具条
//         "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
//         "AMap.PolyEditor", //编辑 折线多，边形
//         "AMap.CircleEditor", //圆形编辑器插件
//         "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
//         "Geocoder", //定位控件，用来获取和展示用户主机所在的经纬度位置
//         "Geolocation"
//     ]
// });
// Vue.use(VueAMap);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
